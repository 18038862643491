<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <h2>Política de Privacidad</h2>
       <p>El desarrollador de Lemon Loan es Lemonade FS, S.A.P.I. de C.V.,SOFOM, E.N.R. . FS, S.A.P.I. de C.V.,</p>
<p>  SOFOM, E.N.R. recopila cierta información sobre usted. Sabemos que nos confías tus datos.También sabemos  </p>
<p> que tenemos la responsabilidad de respetar su privacidad y nos esforzamos por hacerlo. Esta Declaración de</p> 
<p> privacidad explica qué datos personales recopilamos, por qué los usamos, cómo los almacenamos y cómo los </p>
<p>protegemos, y las herramientas que puede usar para administrar su privacidad. Revisaremos periódicamente</p>
 <p>este aviso para reflejar las leyes de privacidad nuevas y en evolución.</p>
<br>
<p>Para comprender claramente el contenido relevante de esta política, debe leer detenidamente el texto</p> 
<p>completo de esta política antes de utilizar productos o servicios relacionados.</p>
 

<h3>Términos y Definiciones Clave</h3>
<p>EMPRESA: Se refiere a Lemonade FS, S.A.P.I. de C.V., SOFOM, E.N.R. (Prestamista), ubicada en Terpel 50 , </p>
<p>Colonia Plaza Textil Bedolla, C.P. 38983, Uriangato, Guanajuato. Cuando se menciona "la Empresa", "nosotros" </p>
<p>o "nuestro".</p>
<p>Usuario: Se refiere a los usuarios de Internet que se registran para utilizar los productos y servicios de Lemon </p>
<p>Loan.</p>
<p>Dispositivo: Cualquier dispositivo conectado a Internet, como teléfono móvil, tableta, computadora u otro </p>
<p>dispositivo utilizado para acceder a Lemon Loan y utilizar sus servicios.</p>
<p>Servicios: Se refiere a los servicios proporcionados por Lemon Loan.</p>
<p>Sitio web: Corresponde al sitio web de Lemon Loan en <a href="www.lemonademex.com." target="_blank">www.lemonademex.com.</a></p>
<p>App: Se refiere a la aplicación de préstamo en línea de Lemon Loan.</p>
<p>Información del Dispositivo Móvil</p>
<p>Después de registrarse y autorizar el acceso a la información de su dispositivo móvil, Lemon Loan recopila </p>
<p>información de dicho dispositivo, como el nombre del dispositivo, modelo, número nativo, configuración </p>
<p>regional y de idioma, sistema operativo y almacenamiento, código IMEI y estado. Esta información se utiliza </p>
<p>para garantizar la seguridad de su cuenta, prevenir fraudes y analizar la estabilidad del servicio, así como para </p>
<p>campañas publicitarias. Sus datos se encriptarán y almacenarán en <a href="https://medii.lemonademex.com" target="_blank">https://medii.lemonademex.com</a></p>
<p>Protegeremos rigurosamente su información y no venderemos, comercializaremos ni divulgaremos sus datos </p>
<p>personales a terceros.</p>
<h3>Introducción de préstamo</h3>
<p>Lemon Loan es una plataforma de préstamos que ofrece efectivo y préstamos de alto valor, bajo interés, sin </p>
<p>garantía y de pago rápido a todos los usuarios en México.</p>
<p>Ofrecemos préstamo hasta $19,000 , no se requiere garantía. También protegemos la privacidad de nuestros </p>
<p>usuarios.</p>


<ul class="list-style">
  <li>ímite de préstamo：$1,000 ~ $19,000</li>
  <li>Plaza de préstamo：90 días a 365 días</li>
  <li>Tasa de interés (Tasa de interés anual máxima)：18.25%</li>
  <li>Tasa impositiva: 10% del interés total.</li>
</ul>
<p>El usuario tomó prestado $3,000 con un plazo de 90 días y las tarifas son las siguientes:</p>
<p>Interés: $3,000*0.05%*90=$135</p>
<p>Impuestos: $135*10%=$13.5</p>
<p>Monto total a pagar = monto del préstamo + interés + Impuestos = $3,000 + $135 + $13.5= $ 3148.5</p>
<h3>Elementos de Colección de Información Personal y Métodos de Recolección</h3>
<p>Lemon Loan proporcionará a los usuarios una evaluación crediticia integral y mejores servicios crediticios, </p>
<p>utilizando tecnología de transmisión de datos encriptados para la transmisión de datos, y la información </p>
<p>recopilada se cargará en <a href="https://medii.lemonademex.com" target="_blank">https://medii.lemonademex.com</a>  para almacenamiento encriptado, Para proteger </p>
<p>seguridad de la información del usuario.Recabamos información para brindar un mejor servicio a los usuarios. </p>
<p>No recabamos sus datos personales sin su permiso.</p>
<h3 >La principal información que recopilamos：</h3>
<h3 class="la" >Información sobre la ubicación：<span>Cuando utiliza nuestros servicios, podemos recopilar su información de </span></h3>
<p>ubicación aproximada para proporcionar servicios regionales.</p>
<h3 >Información del dispositivo：<span>Recopile información de hardware y software, como el modelo de hardware,  </span></h3>
<p>la versión del sistema operativo, la memoria del dispositivo, el identificador de publicidad, el identificador </p>
<p>único de la aplicación, las aplicaciones instaladas, el identificador único del dispositivo, los datos de uso del </p>
<p>dispositivo, etc., para brindarle mejores servicios.</p>
<h3 >Información financiera：<span>Antes de revisar y emitir préstamos, es necesario tener los registros de pago del </span></h3>
<p>usuario, el historial de transacciones y otra información financiera para el análisis antifraude.</p>
<h3 >Información de contacto：<span>Al solicitar un préstamo en Lemon Loan, el usuario debe seleccionar la </span></h3>
<p>información de contacto del contacto de emergencia (que incluye: número de contacto, nombre de contacto, </p>
<p>relación) para confirmar la autenticidad de la información del prestatario y, al mismo tiempo, evaluar el límite </p>
<p>de crédito del prestatario. y realizar análisis antifraude, la información de contacto se cargará en </p>
<p> <a href=" https://medii.lemonademex.com" target="_blank"> https://medii.lemonademex.com</a> para almacenamiento encriptado y nunca se divulgará a nadie sin permiso.</p>
<h3 >Información de identificación personal：<span>Para garantizar que la información proporcionada por el usuario </span></h3>
<p>lsea auténtica y confiable, es necesario recopilar información como el nombre del usuario, número de </p>
<p>identificación y número de teléfono.</p>

<h3 >Permisos de cámara：<span>Algunas de nuestras funciones pueden usar el reconocimiento facial y la carga de </span></h3>
<p>imágenes y, por lo tanto, requieren permisos de usuario de la cámara.</p>

<h3 >Información de la cuenta social：<span>Para acceder al perfil del usuario y a la información de su actividad en </span></h3>
<p>las redes sociales para funciones como interacción social, compartir y recomendaciones personalizadas en la </p>

<p>aplicación.</p>
<h3>Cómo usamos la información que recopilamos:</h3>
<p>Usamos la información recopilada para proporcionar, mantener y mejorar las funciones y servicios de la </p>
<p>aplicación. La información recopilada se utilizará para servicios personalizados, como recomendar contenido, </p>
<p>funciones y actividades promocionales que coincidan con sus intereses y preferencias. Al analizar la </p>
<p>información recopilada, podemos comprender los patrones de comportamiento y los hábitos de uso de los </p>
<p>usuarios para optimizar el diseño de la interfaz y la experiencia del usuario de la aplicación.</p>
<br>
<br>
<p>La información recopilada también se utiliza para soporte técnico y servicio al cliente de la aplicación. </p>
<p>Podemos utilizar la información recopilada para responder a sus consultas, resolver problemas y brindar el </p>
<p>soporte solicitado en función de sus necesidades y comentarios. Esto nos ayuda a comprender mejor sus </p>
<p>necesidades y proporcionar ayuda y asesoramiento específicos.</p>
<h3>Finalidad de la recogida de datos personales:</h3>
<p>Cree y administre cuentas de usuario para que pueda usar las funciones y servicios de la aplicación.</p>
<p>Puede ayudar a las instituciones a realizar una evaluación crediticia y comprender el estado crediticio y la </p>
<p>capacidad de pago del usuario.</p>
<p>Para brindarle servicios personalizados y recomendaciones personalizadas para satisfacer sus necesidades y </p>
<p>preferencias.</p>
<p>Puede brindar servicios y recomendaciones de productos más personalizados, optimizar el diseño funcional de </p>
<p>las aplicaciones, mejorar la experiencia del usuario y brindar servicios más eficientes y convenientes.</p>
<p>Recopile la información de contacto del usuario, como el número de teléfono móvil y la dirección de correo </p>
<p>electrónico, para comunicarse con el usuario sobre asuntos comerciales relacionados, incluido el envío de </p>
<p>información importante, como el progreso de la aprobación del préstamo, el recordatorio de pago y el contrato </p>
<p>de préstamo.</p>
<p>Recopilar información del usuario y, al comprender las necesidades y los comentarios de los usuarios, puede </p>
<p>ayudar a brindar un mejor servicio al cliente y soporte para satisfacer las necesidades individuales de los </p>
<p>usuarios.</p>
<h3>Cómo almacenamos y protegemos su información personal:</h3>
<p>Tomaremos medidas técnicas y organizativas razonables para proteger la seguridad y confidencialidad de su </p>
<p>información personal. Emplearemos medidas como el cifrado de datos, el control de acceso y la auditoría de </p>
<p>seguridad para evitar el acceso, uso o divulgación no autorizados de información personal.</p>
<br>
<br>
<p>Solo conservamos su información personal durante el tiempo necesario para lograr el propósito declarado de </p>
<p>recopilar la información, a menos que se estipule lo contrario por leyes, reglamentos o acuerdos contractuales. </p>
<p>Una vez que la información personal ya no sea necesaria, tomaremos las medidas razonables para destruirla </p>
<p>de manera segura o anonimizarla.</p>
<br>
<br>
<p>Por favor, comprenda que aunque tomaremos medidas de seguridad razonables para proteger su información </p>
<p>personal, no existe una transmisión y almacenamiento de datos absolutamente seguros en el entorno de </p>
<p>Internet. Por lo tanto, no podemos garantizar la seguridad absoluta de la información. Debe comprender que </p>
<p>existen ciertos riesgos y tomar las medidas adecuadas para proteger la seguridad de su información personal.</p>
<h3>Tus derechos:</h3>
<p>Permisos: Tiene derecho a acceder a sus datos personales básicos y a la información de identificación personal </p>
<p>almacenada por nosotros de acuerdo con las leyes aplicables en cualquier momento sin cargo alguno. También </p>
<p>tiene derecho a obtener una copia de su información personal, y le proporcionaremos una copia de su </p>
<p>información personal cuando lo solicite, sujeto al cumplimiento de las leyes pertinentes y técnicamente </p>
<p>factible. También puede iniciar sesión en su cuenta para acceder a su información personal.</p>
<h3 >Corrección:</h3>
<p>puede solicitarnos que corrijamos cualquier información personal incompleta o incorrecta sobre </p>
<p>usted.</p>
<p>Eliminación: puede solicitarnos que eliminemos su información personal en las siguientes circunstancias:</p>
<p>（1）Si nuestro manejo de la información personal viola las leyes y reglamentos o esta política;</p>
<p>（2）Si recopilamos y usamos su información personal sin su consentimiento, o si retira su consentimiento;</p>
<p>（3）Si nuestro manejo de la información personal viola nuestro acuerdo con usted;</p>
<p>（4）Si ya no usa nuestros productos o servicios, o cancela su cuenta;</p>
<p>（5）Si ya no le proporcionamos productos o servicios, o el período de retención ha expirado.</p>
<br>
<br>
<p>Restricciones en el procesamiento automático de decisiones: En algunas funciones comerciales, podemos </p>
<p>brindarle servicios personalizados basados en mecanismos automáticos de toma de decisiones no manuales.Si </p>
<p>tiene alguna pregunta, puede contactarnos.</p>
<p>Objeto: Puede oponerse al procesamiento de su información personal con fines de marketing directo en </p>
<p>cualquier momento.</p>
<p>Cambio en el alcance del consentimiento: tiene derecho a retirar su consentimiento en cualquier momento sin </p>
<p>afectar la legalidad de nuestro procesamiento basado en ese consentimiento antes de su retiro.</p>
<h3 >Cancelar cuenta:</h3>
<p>puede cancelar su cuenta. Después de que cancele su cuenta, dejaremos de brindarle </p>
<p>productos y servicios, y eliminaremos o anonimizaremos su información personal de acuerdo con sus </p>
<p>requisitos, a menos que las leyes y reglamentos estipule lo contrario.</p>
<p>El derecho a solicitar una explicación: Tiene derecho a pedirnos que le expliquemos esta política. Comuníquese </p>
<p>con nosotros a través de la información de contacto provista en esta política.</p>
<h3 >Protección de menores:</h3>
<p>Damos gran importancia a la protección de la información personal de los menores. Para los usuarios menores</p>
<p>de edad (de acuerdo con las leyes y regulaciones de su país o región), tomaremos medidas adicionales para </p>
<p>proteger la privacidad y seguridad de su información personal. Antes de recopilar información personal de </p>
<p>menores, haremos todo lo posible para obtener el consentimiento explícito del tutor legal. Si es menor de </p>
<p>edad, solicite el consentimiento de sus padres o tutores legales antes de usar la aplicación y permítales leer y </p>
<p>comprender esta política de privacidad.</p>
<h3>Cambios a esta Política:</h3>
<p>Es posible que actualicemos esta Política de privacidad de vez en cuando para reflejar los cambios en la </p>
<p>aplicación y las actualizaciones de las leyes y reglamentos. Cuando se realicen cambios importantes, </p>
<p>proporcionaremos avisos destacados en la aplicación y le pediremos su consentimiento expreso cuando sea </p>
<p>necesario.</p>
<br>
<br>
<p>Le recomendamos que revise esta Política de privacidad periódicamente para comprender cómo procesamos y </p>
<p>protegemos su información personal. Si continúa utilizando la aplicación, está de acuerdo y acepta el </p>
<p>contenido de esta política de privacidad.</p>
<h3>Contáctenos:</h3>
<p>Email:<a href="admin@lemonademex.com"  target="_blank">  admin@lemonademex.com</a>  </p>
<p>Página web oficial：<a href="www.lemonademex.com "  target="_blank">  www.lemonademex.com </a></p>
<p>Business Physical Address: Terpel 50 , Colonia Plaza Textil Bedolla, C.P. 38983, Uriangato, Guanajuato</p>
<p>Nombre de empresa：Lemonade FS, S.A.P.I. de C.V., SOFOM, E.N.R.</p>

      </div>
    </div>
    <!-- <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div> -->
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
  </div>
</template>

<script>
import productImg1 from "@/page/lemonade/components/img/3_1.png";
import productImg2 from "@/page/lemonade/components/img/3_2.png";
import productImg3 from "@/page/lemonade/components/img/4_3.png";
export default {
  data() {
    return {
      productImg1: productImg1,
      productImg2: productImg2,
      productImg3: productImg3,
      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  
  background-color: #ffffff;
}
.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  margin-top: 120px;
}
.imgBox1{
    display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.img1{
    margin-left: 220px;
  margin-right: 220px;
}
.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
  margin-top: 100px;
}

img {
  display: block;
}


.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
p{
   max-width: 100%;
  margin-top: 10px;
  font-size: 18px;
}
 ul li {
  list-style-type: disc !important; 
}
.text2{
    font-family: Inter-Extra Bold;

}
span{
  font-weight: normal; /* 取消加粗 */
 font-size: 18px;
}
h3{
  margin-top: 40px;
}
a{
  text-decoration: none;
  color: rgb(34, 121, 236);
}
  .list-style {
    list-style-type: disc;
    margin-left: 20px; 
    font-size: 18px;

  }
  li{
    margin-top: 20px;
  }
  li::marker {
    color: rgb(49, 144, 238); /* 小圆点颜色 */
  }
  .la{
    margin-top: 10px;
  }
</style>
